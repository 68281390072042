<template>
  <v-dialog v-model="activeDialog" max-width="400" persistent>
    <v-card>
      <v-card-title>{{ name }} active</v-card-title>
      <v-card-text>
        <v-switch
          v-model="add.status"
          :label="`Active: ${add.status.toString()}`"
        ></v-switch>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="red" small text @click="closeDialog" v-on="on">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="blue" small text @click="submit" v-on="on">
              submit
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
//   import Restful from "@/services/RestFul";
//   import moment from "moment";
export default {
  props: {
    activeDialog: Boolean,
    myId: { type: Number },
    status: { type: Boolean },
    name: { type: String },
  },
  data() {
    return {
      add: {
        status: this.status,
      },
    };
  },
  created() {},
  methods: {
    closeDialog() {
      let payload = { id: this.myId, state: false };
      this.$emit("closeActive", payload);
    },
    submit() {
      let status = this.add.status;
      let data = { status };
      let payload = { id: this.myId, state: false, data: data };
      this.$emit("submitActive", payload);
    },
  },
};
</script>
