<template>
  <v-dialog v-model="newDialog" max-width="700" persistent>
    <v-card :loading="loading" loader-height="2">
      <v-card-title>new stock</v-card-title>
      <v-card-text>
        <v-autocomplete
          v-model="add.medicinesid"
          :items="pharmacatlist"
          dense
          item-text="name"
          item-value="id"
          label="medicine category"
          return-object
          :hint="`${add.medicinesid.eml} | ${add.medicinesid.formulations}`"
        ></v-autocomplete>

        <v-row dense>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="add.brand_name"
              dense
              label="brand name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="add.brand_description"
              dense
              label="description"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="add.manufacturer"
              dense
              label="manufacturer"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="add.bar_code"
              dense
              label="barcode"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="6">
            <v-row>
              <v-col cols="12" sm="8">
                <v-text-field
                  v-model="add.weight"
                  dense
                  label="weight"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  v-model="add.weight_measurements_id"
                  :items="myMasslist"
                  dense
                  item-text="shortcut"
                  item-value="id"
                  label="measure"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6">
            <v-row>
              <v-col cols="12" sm="8">
                <v-text-field
                  v-model="add.volume"
                  dense
                  label="volume"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  v-model="add.volume_measurements_id"
                  :items="myVolumelist"
                  dense
                  item-text="shortcut"
                  item-value="id"
                  label="volume"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="add.stock"
              dense
              label="stock"
              type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              min-width="auto"
              offset-y
              transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="add.expiry"
                  dense
                  label="Expiry date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="add.expiry"
                :min="today"
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="red" small text @click="closeDialog" v-on="on">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              :disabled="$v.add.$invalid"
              color="blue"
              small
              text
              @click="submit"
              v-on="on"
            >
              submit
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Restful from "@/services/RestFul";
import Utils from "../../mixins/utils";
import Measurements from "../../mixins/measurements";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { VCol } from "vuetify/lib";

export default {
  mixins: [Utils, Measurements, validationMixin],
  props: {
    newDialog: Boolean,
    myId: { trype: Number },
  },
  data() {
    return {
      loading: false,
      pharmacatlist: [],
      menu2: null,
      today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      add: {
        medicinesid: 1,
        brand_name: null,
        brand_description: null,
        bar_code: null,
        manufacturer: null,
        weight: 0,
        weight_measurements_id: 4,
        volume: 0,
        volume_measurements_id: 3,
        stock: 1,
        expiry: null,
      },
    };
  },
  validations: {
    add: {
      medicinesid: {
        required,
      },
      brand_name: {
        required,
      },
      brand_description: { required },
      stock: { required },
      expiry: { required },
    },
  },
  created() {
    this.pharmacat();
  },
  methods: {
    pharmacat() {
      this.loading = true;
      let self = this;
      Restful.general.medicines
        .list()
        .then((response) => {
          console.log(response.data);
          this.pharmacatlist = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    closeDialog() {
      let payload = { state: false };
      this.$emit("closeNew", payload);
    },
    submit() {
      let medicinesid = this.add.medicinesid.id;
      let brand_name = this.add.brand_name;
      let brand_description = this.add.brand_description;
      let bar_code = this.add.bar_code;
      let manufacturer = this.add.manufacturer;
      let weight = parseInt(this.add.weight);
      let weight_measurements_id = this.add.weight_measurements_id;
      let volume = parseInt(this.add.volume);
      let volume_measurements_id = this.add.volume_measurements_id;
      let stock = parseInt(this.add.stock);
      let expiry = Date.parse(this.add.expiry);

      let data = {
        expiry,
        manufacturer,
        medicinesid,
        brand_name,
        brand_description,
        bar_code,
        weight,
        weight_measurements_id,
        volume,
        volume_measurements_id,
        stock,
      };
      console.log("data:", data);
      let payload = { data: data, state: false };
      this.$emit("submitNew", payload);
    },
  },
  components: { VCol },
};
</script>
