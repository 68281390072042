<template>
  <div class="page">
    <div class="heading">
      <v-toolbar :flat="flatapp" dense>
        <v-btn icon @click="menulink">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-breadcrumbs
          :items="crumbs"
          class="capitalize"
          style="padding: 14px 20px"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-btn color="primary" text x-small @click.stop="newDialog = true">
          new stock
        </v-btn>
        <SubserviceNew
          :newDialog="newDialog"
          @closeNew="closeNew"
          @submitNew="submitNew"
        />

        <v-btn color="primary" icon @click="refresh">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <!-- <v-btn icon color="primary" @click="return">
          <v-icon>mdi-keyboard-return</v-icon>
        </v-btn> -->
      </v-toolbar>
      <v-divider></v-divider>
    </div>

    <div class="body">
      <v-card flat>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="stocklist"
            :items-per-page="10"
            :loading="loading"
            loader-height="1"
          >
            <template v-slot:item.brand_name="{ item }">
              {{ item.brand_name | capitalize }}
            </template>

            <template v-slot:item.medicine="{ item }">
              {{ item.medicine | capitalize }}
            </template>

            <template v-slot:item.stock="{ item }">
              {{ comma(item.stock) }}
            </template>

            <template v-slot:item.brand_description="{ item }">
              {{ item.brand_description | capitalize }}
            </template>

            <template v-slot:item.weight="{ item }">
              {{ item.weight }} {{ item.weight_measure }}
            </template>

            <template v-slot:item.volume="{ item }">
              {{ item.volume }} {{ item.volume_measure }}
            </template>

            <template v-slot:item.expire="{ item }">
              {{ item.expiry | myDate }}
            </template>

            <template v-slot:item.status="{ item }">
              <v-btn icon @click.stop="$set(activeDialog, item.id, true)">
                <v-icon :color="item.status ? 'primary' : 'error'">
                  {{ item.status ? "mdi-check" : "mdi-close" }}
                </v-icon>
              </v-btn>
              <SubserviceStatus
                v-if="activeDialog[item.id]"
                :activeDialog="activeDialog[item.id]"
                :myId="item.id"
                :name="item.brand_name"
                :status="item.status"
                @closeActive="closeActive"
                @submitActive="submitActive"
              />
            </template>

            <template v-slot:item.id="{ item }">
              <v-btn
                color="primary"
                text
                x-small
                @click.stop="$set(viewDialog, item.id, true)"
              >
                view
              </v-btn>
              <SubserviceView
                v-if="viewDialog[item.id]"
                :myId="item.id"
                :viewDialog="viewDialog[item.id]"
                @closeView="closeView"
              />

              <v-btn
                color="success"
                text
                x-small
                @click.stop="$set(updateDialog, item.id, true)"
              >
                edit
              </v-btn>
              <SubserviceEdit
                v-if="updateDialog[item.id]"
                :myId="item.id"
                :updateDialog="updateDialog[item.id]"
                @closeUpdate="closeUpdate"
                @submitUpdate="submitUpdate"
              />

              <v-btn
                color="error"
                text
                x-small
                @click.stop="$set(removeDialog, item.id, true)"
              >
                delete
              </v-btn>
              <SubserviceRemove
                v-if="removeDialog[item.id]"
                :myId="item.id"
                :removeDialog="removeDialog[item.id]"
                @closeRemove="closeRemove"
                @submitRemove="submitRemove"
              />
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </div>
    <v-snackbar
      v-model="snack.bar"
      :color="snack.color"
      :multi-line="snack.multiLine"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import WinScroll from "../../mixins/windowscroll";
import Util from "../../mixins/utils";
import SubserviceNew from "../../components/stocks/newone.vue";
import SubserviceView from "../../components/stocks/view.vue";
import SubserviceEdit from "../../components/stocks/edit.vue";
import SubserviceRemove from "../../components/stocks/remove.vue";
import SubserviceStatus from "../../components/stocks/status.vue";

export default {
  components: {
    SubserviceNew,
    SubserviceView,
    SubserviceEdit,
    SubserviceRemove,
    SubserviceStatus,
  },
  mixins: [Util, WinScroll("position")],
  data() {
    return {
      newDialog: false,
      activeDialog: {},
      editDialog: {},
      updateDialog: {},
      viewDialog: {},
      removeDialog: {},
      shopMapDialog: {},
      crumbs: [
        {
          link: true,
          text: "Dashboard",
          to: { name: "pharma.dash" },
        },
        {
          link: true,
          text: "stocks",
          to: { name: "pharma.stocks" },
        },
      ],
      snack: {
        color: "info",
        multiLine: true,
        bar: false,
        text: "Processing ... ",
      },
      stocklist: [],
      loading: false,
      headers: [
        { text: "name", value: "brand_name" },
        { text: "medicine", value: "medicine" },
        { text: "description", value: "brand_description" },
        { text: "weight", value: "weight" },
        { text: "volume", value: "volume" },
        { text: "stock", value: "stock" },
        { text: "expiry", value: "expire" },
        { text: "active", value: "status", align: "center" },
        { text: "action", value: "id", align: "center" },
      ],
    };
  },
  computed: {
    flatapp: function () {
      if (this.position[1] > 30) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    this.list();
  },
  methods: {
    list() {
      let self = this;
      Restful.subscribers.pharma_stocks
        .list()
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.stocklist = response.data;
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    refresh() {
      this.list();
    },
    menulink() {
      this.$emit("sidebar");
    },
    closeEdit(p) {
      this.$set(this.editDialog, p.id, p.state);
    },
    submitEdit(p) {
      console.log(p);
      this.snack.bar = true;
      let self = this;
      Restful.subscribers.subsidiary
        .update(p.id, p.data)
        .then((response) => {
          // console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.list();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.$set(this.editDialog, p.id, p.state);
    },
    closeNew(p) {
      this.newDialog = p.state;
    },
    submitNew(p) {
      console.log(p);
      this.snack.bar = true;
      let self = this;
      Restful.subscribers.pharma_stocks
        .create(p.data)
        .then((response) => {
          console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.list();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.newDialog = p.state;
    },
    closeUpdate(p) {
      this.$set(this.updateDialog, p.id, p.state);
    },
    submitUpdate(p) {
      this.snack.bar = true;
      let self = this;
      Restful.subscribers.pharma_stocks
        .update(p.id, p.data)
        .then((response) => {
          // console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.list();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.$set(this.updateDialog, p.id, p.state);
    },
    closeView(p) {
      this.$set(this.viewDialog, p.id, p.state);
    },
    closeRemove(p) {
      this.$set(this.removeDialog, p.id, p.state);
    },
    submitRemove(p) {
      this.snack.bar = true;
      let self = this;
      Restful.subscribers.pharma_stocks
        .remove(p.id)
        .then((response) => {
          // console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.list();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.$set(this.removeDialog, p.id, p.state);
    },
    closeMap(p) {
      this.$set(this.shopMapDialog, p.id, p.state);
    },
    closeActive(p) {
      this.$set(this.activeDialog, p.id, p.state);
    },
    submitActive(p) {
      console.log(p);
      this.snack.bar = true;
      let self = this;
      Restful.subscribers.pharma_stocks
        .status(p.id, p.data)
        .then((response) => {
          // console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.list();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.$set(this.activeDialog, p.id, p.state);
    },
  },
};
</script>
