<template>
  <v-dialog v-model="viewDialog" max-width="1000" persistent scrollable>
    <v-card :loading="loading" loader-height="2">
      <v-card-title>View Detail</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6">
            <div class="mylist">
              <ul>
                <li>
                  <div class="myleft">medicine</div>
                  <div class="myright">{{ view.medicine }}</div>
                  <div class="clearall"></div>
                </li>
                <li>
                  <div class="myleft">brand name</div>
                  <div class="myright">{{ view.brand_name }}</div>
                  <div class="clearall"></div>
                </li>
                <li>
                  <div class="myleft">brand description</div>
                  <div class="myright">{{ view.brand_description }}</div>
                  <div class="clearall"></div>
                </li>
                <li>
                  <div class="myleft">barcode</div>
                  <div class="myright">{{ view.bar_code }}</div>
                  <div class="clearall"></div>
                </li>
                <li>
                  <div class="myleft">manufacturer</div>
                  <div class="myright">
                    {{ view.manufacturer | capitalize }}
                  </div>
                  <div class="clearall"></div>
                </li>
                <li>
                  <div class="myleft">stock</div>
                  <div class="myright">
                    {{ comma(view.stock) }}
                  </div>
                  <div class="clearall"></div>
                </li>
              </ul>
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <div class="mylist">
              <ul>
                <li>
                  <div class="myleft">expiry</div>
                  <div class="myright">{{ view.expiry | myDate }}</div>
                  <div class="clearall"></div>
                </li>
                <li>
                  <div class="myleft">volume</div>
                  <div class="myright">
                    {{ view.volume }} {{ view.volume_measure }}
                  </div>
                  <div class="clearall"></div>
                </li>
                <li>
                  <div class="myleft">weight</div>
                  <div class="myright">
                    {{ view.weight }} {{ view.weight_measure }}
                  </div>
                  <div class="clearall"></div>
                </li>
                <li>
                  <div class="myleft">created</div>
                  <div class="myright">{{ view.created | myDating }}</div>
                  <div class="clearall"></div>
                </li>
                <li>
                  <div class="myleft">updated</div>
                  <div class="myright">{{ view.updated | myDating }}</div>
                  <div class="clearall"></div>
                </li>
                <li>
                  <div class="myleft">active</div>
                  <div class="myright">{{ view.status ? "Yes" : "No" }}</div>
                  <div class="clearall"></div>
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
        <br />
      </v-card-text>
      <v-card-actions>
        <span class="error_message">{{ responseMessage }}</span>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="red" small text @click="closeDialog" v-on="on">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Restful from "@/services/RestFul";
//   import moment from "moment";
import Utils from "@/mixins/utils.js";

export default {
  props: {
    viewDialog: Boolean,
    myId: { type: Number, default: 0 },
    name: { type: String, default: null },
  },
  mixins: [Utils],
  data() {
    return {
      responseMessage: null,
      loading: false,
      view: {},
    };
  },
  created() {
    this.find();
  },
  methods: {
    find() {
      this.loading = true;
      let self = this;
      Restful.subscribers.pharma_stocks
        .find(this.myId)
        .then((response) => {
          console.log(response.data);
          this.view = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    closeDialog() {
      let payload = { id: this.myId, state: false };
      this.$emit("closeView", payload);
    },
  },
};
</script>
